import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { register } from "../actions/backOfficeAuthAction";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required.
      </div>
    );
  }
};

const vusername = (value) => {

  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Username must be an email address.
      </div>
    );
  }

};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const Register = () => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState("");
  const [successful, setSuccessful] = useState(false);

  const backofficeAuthDetails = useSelector(state => state.backofficeAuthState);
  const { message, registerSucceeded } = backofficeAuthDetails;
  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangePasswordConfirm = (e) => {
    const passwordConfirm = e.target.value;
    setPasswordConfirm(passwordConfirm);
  };

  const onBlurPassword = (e) => {
    if(password && passwordConfirm) {
      if(password !== passwordConfirm) {
        setPasswordMatch(false);
        setPasswordMessage("Passwords do not match");
      }
      else {
        setPasswordMatch(true);
        setPasswordMessage("");
      }
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setSuccessful(false);

    if(password !== passwordConfirm) {
      setPasswordMatch(false);
      setPasswordMessage("Passwords do not match");
    }
    else {
      setPasswordMatch(true);
      setPasswordMessage("");

      form.current.validateAll();

      if (checkBtn.current.context._errors.length === 0) {
        dispatch(register(username, password));
      }
    }

  };

  return (
    <div className="col-md-12">
      <div className="card card-container cetgc-card-container">
        <h2 className="cet-gc-page-title">Registration</h2>
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card cetgc-avatar-img"
        />

        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={onChangeUsername}
                  validations={[required, vusername]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  onBlur={onBlurPassword}
                  validations={[required, vpassword]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password-confirm">Confirm Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password-confirm"
                  value={passwordConfirm}
                  onChange={onChangePasswordConfirm}
                  onBlur={onBlurPassword}
                  validations={[required, vpassword]}
                />
              </div>

              <div className="form-group">
                <button className="btn btn-primary btn-block">Sign Up</button>
              </div>
            </div>
          )}

          {passwordMessage && (
            <div className="form-group">
              <div className={ passwordMatch ? "alert alert-success" : "alert alert-danger" } role="alert">
                {passwordMessage}
              </div>
            </div>
          )}
          {message && (
            <div className="form-group">
              <div className={ registerSucceeded ? "alert alert-success" : "alert alert-danger" } role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default Register;
