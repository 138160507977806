import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
	userLoginReducer,
	updatedValuesReducer,
	updateUserData,
} from '../reducer/authReducer';
import {
	userOrdersReducer,
	updateOrderData,
	getOrderStatus,
} from '../reducer/orderReducer';
import {
	backOfficeAuthReducer
} from '../reducer/backOfficeAuthReducer'

const reducer = combineReducers({
	loginDetails: userLoginReducer,
	updatedValues: updatedValuesReducer,
	ordersInfo: userOrdersReducer,
	updateState: updateUserData,
	updateOrder: updateOrderData,
	orderStatus: getOrderStatus,
	backofficeAuthState: backOfficeAuthReducer,
});

const userLoginStorage = localStorage.getItem('userInfo')
	? JSON.parse(localStorage.getItem('userInfo'))
	: null;
const orderStorage = localStorage.getItem('orders')
	? JSON.parse(localStorage.getItem('orders'))
	: null;
const middleware = [thunk];
const initialState = {
	loginDetails: { userInfo: userLoginStorage },
	ordersInfo: { orders: orderStorage },
};

const store = createStore(
	reducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middleware))
);
export default store;
