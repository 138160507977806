import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import { select, Form, Button } from 'react-bootstrap';
import { sendFileData,activateReport } from '../actions/backOfficeAction';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { ToastContainer, toast } from 'react-toastify';
import { logout } from '../actions/backOfficeAuthAction';


const BackOfficeScreen = () => {
	const [selectedFile, setSelectedFile] = useState(null);
	const [errorMessage, setErrorMessage] = useState("");

	const dispatch = useDispatch();
	const onFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
		setErrorMessage("");
	};

	const submitFileHandler = (e) => {
		e.preventDefault();

		// Only submit the file if a file has actually been chosen
		if(selectedFile != null) {
			dispatch(sendFileData(selectedFile,e));
		}
		else {
			setErrorMessage("You must select a file to proceed");
		}
	};

	const activateHandler = (e) => {
		e.preventDefault();

		dispatch(activateReport());
	};

	const logOutHandler = (e) => {

		dispatch(logout());

	}

	return (
		<div className="col-md-12">
      		<div className="card card-container cetgc-backoffice-screen-container">
				<ToastContainer />{/*<Tabs defaultActiveKey="upload" id="uncontrolled-tab-example" className="mb-3">
				<Tab eventKey="upload" title="Upload" >*/}
				<form name="myForm" encType="multipart/form-data" action="" onSubmit={submitFileHandler}>
						<div className="backoffice-logout-link">
							<Button variant="link" onClick={logOutHandler}>
								Log Out
							</Button>
						</div>
						<h2 className="cet-gc-page-title">Back Office Dashboard</h2>
						<div className="form-group">
							<p align="left" className="backoffice-paragraph"><strong>Select file to upload</strong></p> 
						<input
							type="file"
							name="file"
								className="form-control-file"
							id="ExcelFormControlFile"
							onChange={(e) => onFileChange(e)}
						></input>
					</div>
						<div className="backoffice-error-messages">
							{errorMessage.length > 0 ? <p>{errorMessage}</p> : ""}
						</div>
						<div className="backoffice-button-group">
						<Button variant="primary" type="submit" className="upload-button">
							Upload
						</Button>
						<Button variant="secondary" type="submit" onClick={activateHandler}> 
							Activate
						</Button>
					</div>
				</form>
				</div>
			</div>
	);
};

export default BackOfficeScreen;
